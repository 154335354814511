import { external } from '@emobg/web-api-client';

const {
  paymentsProvider, paymentsCompany, paymentsUser, tariffs,
} = external;

export const getUserPaymentMethodsCollection = uuid => paymentsUser.getUserPaymentMethods(uuid);
export const addUserNewPaymentMethod = (uuid, payload) => paymentsUser.postPaymentMethodPSD2(uuid, payload);
export const setUserDefaultPaymentMethod = (uuid, payload) => paymentsUser.putLinkPaymentMethodToUser(uuid, payload, 'v3');
export const removeUserPaymentMethod = (userUuid, payload) => paymentsUser.putRemovePaymentMethod(userUuid, payload);

export const getCompanyPaymentMethodsCollection = uuid => paymentsCompany.getCompanyPaymentMethods(uuid);
export const addCompanyNewPaymentMethod = (uuid, payload) => paymentsCompany.postCompanyPaymentMethod(uuid, payload);
export const setCompanyDefaultPaymentMethod = (
  companyUuid, paymentMethodUuid, userUuid,
) => paymentsCompany.putSetDefaultPaymentMethod(companyUuid, paymentMethodUuid, userUuid);
export const removeCompanyPaymentMethod = (companyUuid, payload) => paymentsCompany.putRemovePaymentMethod(companyUuid, payload);

// tariffs
export const postUserTariffsPayment = (tariffUuid, payload) => paymentsUser.postTariffsPayment(tariffUuid, payload);
export const postCompanyTariffsPayment = (tariffUuid, payload) => paymentsCompany.postTariffsPayment(tariffUuid, payload);

export const postUserMigratedTariffsPayment = (
  userUuid, tariffUuid, payload,
) => tariffs.postMigratedTariffToUser(userUuid, tariffUuid, payload);
export const postCompanyMigratedTariffsPayment = (
  companyUuid, tariffUuid, payload,
) => tariffs.postMigratedTariffToCompany(companyUuid, tariffUuid, payload);

// insurances
export const postUserInsurancePayment = (userUuid, payload) => paymentsUser.postInsurancesPayment(userUuid, payload);

// booking
export const postBookingInitPayment = (invoiceUuid, payload) => paymentsProvider.postBookingInitPayment(invoiceUuid, payload);
export const putBookingResolvePayment = (bookingUuid, payload) => paymentsProvider.putBookingResolvePayment(bookingUuid, payload);

export const putEditBookingPayment = (bookingUuid, payload) => paymentsUser.putEditBookingPayment(bookingUuid, payload, 'v5');

export const createPendingBooking = reservationRequest => paymentsProvider.postCreatePendingBooking(reservationRequest);
export const cancelPendingBooking = (bookingUuid, payload) => paymentsProvider.putCancelPendingBooking(bookingUuid, payload);
